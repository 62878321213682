import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import ArticlePreview from 'components/ArticlePreview';

import { IArticlePreviewComponentProps as IKidsHubArticlePreview } from 'components/ArticlePreview/model';
import CustomCard from 'components/CustomCard';
import { removeHTMLTags } from 'utils/helpers';
import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';

import { IArticleCardList } from './model';

import './ArticleCardList.scss';

const ArticleCardList: FC<IArticleCardList> = ({
  areArticlesPartOfKidsHub,
  articles,
  sorryMessage,
  isArticlesCounter = true,
  itemsTotal,
  isSmallDevice,
  isShowLabels,
  isArticlesCardListInViewport,
  countFilteredItems,
  counterFirstWord,
  counterMiddleWord,
}) => (
  <div className="article-card-list" data-test="ArticleCardList">
    <Container fluid>
      {articles?.length ? (
        <div
          className={`article-card-list__${
            areArticlesPartOfKidsHub ? 'kidsHub-article-cards' : 'article-cards'
          }`}
        >
          {isArticlesCounter ? (
            <p className="article-card-list__counter">
              {`${counterFirstWord} ${countFilteredItems} ${counterMiddleWord} ${itemsTotal}`}
            </p>
          ) : null}
          {!areArticlesPartOfKidsHub
            ? (articles as ArticleListingTypes.IArticleCard[]).map(
                (
                  {
                    link,
                    title,
                    featuredImage,
                    preferred,
                    description,
                    productFamily,
                  }: ArticleListingTypes.IArticleCard,
                  articleIndex
                ) => (
                  <CustomCard
                    cardLink={{ link }}
                    key={link}
                    title={title}
                    label={isShowLabels ? preferred?.[0]?.title : undefined}
                    labelBgColor={
                      productFamily?.[0]?.articleListingBadgeBgColor?.[0]?.properties?.colorPicker
                        ?.label || DEFAULT_PRODUCT_FAMILY_COLOR
                    }
                    text={removeHTMLTags(description)}
                    img={
                      featuredImage?.[0]?.properties?.image?.gatsbyImage?.childImageSharp
                        ? featuredImage?.[0]?.properties?.image
                        : featuredImage?.[0]?.properties?.image.fallbackUrl
                    }
                    imageAlt={featuredImage?.[0]?.properties.imageAlt}
                    imgType="img"
                    classes="article-card"
                    isLazyLoading={
                      !(isSmallDevice && isArticlesCardListInViewport && articleIndex === 0)
                    }
                  />
                )
              )
            : ((articles as any).map(({ fullWidthBanner, previewImage, ...article }) => ({
                ...article,
                previewImage: previewImage ?? fullWidthBanner[0].properties,
              })) as IKidsHubArticlePreview[]).map(
                ({
                  link,
                  title,
                  tags,
                  previewImage,
                  isVideo,
                  description,
                  preferred,
                }: IKidsHubArticlePreview) => (
                  <div>
                    <ArticlePreview
                      link={link}
                      title={title}
                      tags={tags}
                      previewImage={previewImage}
                      isVideo={isVideo}
                      description={description}
                      preferred={preferred?.[0]}
                    />
                  </div>
                )
              )}
        </div>
      ) : (
        <h3 className="article-list-no-result">{sorryMessage}</h3>
      )}
    </Container>
  </div>
);
export default ArticleCardList;
