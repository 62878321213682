import { IArticleListFilterData } from 'components/ArticleListFilter';
import {
  parseCategories,
  parseCheckboxStyles,
  parseCTAButtonData,
  parseCustomGlobalStyles,
  parseFilterSections,
  parseFilterButtonsTexts,
} from 'components/AlgoliaFilters/parsers';
import { FILTER_NAVIGATION_PARAMS } from 'utils/algolia/constants';
import { IArticlePreviewComponentProps as IKidsHubArticlePreview } from 'components/ArticlePreview/model';

export const articlesFiltersDefaultData: IArticleListFilterData = {
  isArticleListingPartOfKidsHub: false,
  categoryFacetName: '',
  showFilters: false,
  filterButtonsTexts: {
    filtersSelectLabel: 'Select',
    filtersHaveSelectedLabel: `You've selected`,
    filtersTogglerButtonText: 'Filters',
    closeButtonText: 'Close',
    seeMoreButtonText: 'See more',
    seeLessButtonText: 'See less',
  },
  categories: [],
  categorySectionTitle: '',
  filterSections: [],
  customGlobalStyles: {},
  showResultsBtn: { title: '' },
  clearAllBtn: { title: '' },
  filterMenuBgColor: null,
  checkboxStyles: {},
  currentPageId: '',
  config: {
    cardsNumDesktop: 12,
    cardsNumMobile: 9,
    showLabels: false,
    sorryMessage: '',
  },
  defaultArticles: [],
  masterPageId: 0,
  masterPageLink: '',
  showManuallySelectedArticles: false,
  lang: '',
  removeAppliedFilterAriaLabel: '',
};

export const parseArticlesFilters = (
  param: AppFilters.IUmbracoFiltersMainProperties,
  showFilters: boolean,
  isFixedFilters: boolean,
  counterFirstWord: string | undefined,
  counterMiddleWord: string | undefined,
  currentPageId: string,
  config: ArticleListingTypes.IUmbracoArticleListingConfig,
  articles: Array<ArticleListingTypes.IArticleCard | IKidsHubArticlePreview>,
  showManuallySelectedArticles: UmbracoTypes.TBoolean,
  articlesLinks: string[],
  lang: string,
  isUnbrandedType: boolean,
  isArticleListingPartOfKidsHub: boolean | undefined,
  isArticlesCounterDisplayed: boolean,
  filtersTitle: string | undefined
): IArticleListFilterData => {
  const result: IArticleListFilterData = {
    config: {
      cardsNumDesktop: config?.cardsNumDesktop || articlesFiltersDefaultData.config.cardsNumDesktop,
      cardsNumMobile: config?.cardsNumMobile || articlesFiltersDefaultData.config.cardsNumMobile,
      showLabels: config?.showLabels === '1',
      sorryMessage: config?.sorryMessage,
    },
    isArticleListingPartOfKidsHub: isArticleListingPartOfKidsHub ?? false,
    categoryFacetName: isArticleListingPartOfKidsHub
      ? FILTER_NAVIGATION_PARAMS.kidsHubArticleCategoryFacet
      : FILTER_NAVIGATION_PARAMS.articleCategoryFacet,
    categories:
      param.categoriesList?.length && !isUnbrandedType
        ? parseCategories(param.categoriesList[0])
        : articlesFiltersDefaultData.categories,
    currentPageId,
    categorySectionTitle: param.categoriesList?.length
      ? param.categoriesList[0].properties.categorySectionTitle
      : articlesFiltersDefaultData.categorySectionTitle,
    filterSections: param.filterSections
      ? parseFilterSections(param.filterSections)
      : articlesFiltersDefaultData.filterSections,
    customGlobalStyles: param.customGlobalStyles?.length
      ? parseCustomGlobalStyles(param.customGlobalStyles[0])
      : articlesFiltersDefaultData.customGlobalStyles,
    showResultsBtn: param.showResultsBtn
      ? parseCTAButtonData(param.showResultsBtn[0])
      : articlesFiltersDefaultData.showResultsBtn,
    clearAllBtn: param.clearAllBtn
      ? parseCTAButtonData(param.clearAllBtn[0])
      : articlesFiltersDefaultData.clearAllBtn,
    filterMenuBgColor: param.filterMenuBgColor
      ? param.filterMenuBgColor[0]?.properties.colorPicker?.label || null
      : articlesFiltersDefaultData.filterMenuBgColor,
    checkboxStyles: param.checkboxStyles
      ? parseCheckboxStyles(param.checkboxStyles[0])
      : articlesFiltersDefaultData.checkboxStyles,
    defaultArticles:
      articles && articles.length ? articles : articlesFiltersDefaultData.defaultArticles,
    filterButtonsTexts: param.filterButtonsTexts
      ? parseFilterButtonsTexts(param.filterButtonsTexts[0])
      : articlesFiltersDefaultData.filterButtonsTexts,
    masterPageId: param.masterPageId,
    masterPageLink: param.masterPageLink,
    showFilters,
    isFixedFilters,
    counterFirstWord,
    counterMiddleWord,
    isArticlesCounterDisplayed,
    showManuallySelectedArticles: showManuallySelectedArticles === '1',
    articlesLinks,
    lang,
    removeAppliedFilterAriaLabel: param.removeAppliedFilterAriaLabel,
    filtersTitle,
  };

  return result;
};
