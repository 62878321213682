import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import loadable from '@loadable/component';

import useScreenRecognition from 'hooks/useScreenRecognition';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useOnViewport from 'hooks/useOnViewport';

import ArticleCardList from 'components/ArticleCardList';
import Pagination from 'components/Pagination';

import { IArticlePreviewComponentProps as IKidsHubArticlePreview } from 'components/ArticlePreview/model';
import { IPropsArticleListFilter } from './model';

import './ArticleListFilter.scss';

import Helpers from './helpers';

const COMPONENT_ID = 'articleListFilter';

const AlgoliaFilters = loadable(() => import('components/AlgoliaFilters'));

const ArticleListFilter: FC<IPropsArticleListFilter> = (props): ReactElement | null => {
  const { isMobile, isMiddleTablet } = useScreenRecognition();
  const [filters, setFilters] = useState<AppFilters.IChosenFilterItemData[]>();
  const articlesCardListRef = useRef<HTMLDivElement>(null);
  const isSmallDevice = isMobile || isMiddleTablet;

  const {
    categoryFacetName,
    filterButtonsTexts,
    categories,
    currentPageId,
    filterSections,
    categorySectionTitle,
    customGlobalStyles,
    showResultsBtn,
    clearAllBtn,
    filterMenuBgColor,
    checkboxStyles,
    masterPageId,
    masterPageLink,
    defaultArticles,
    bgSectionColor = 'white',
    config: { cardsNumMobile, cardsNumDesktop, showLabels, sorryMessage },
    showManuallySelectedArticles,
    articlesLinks,
    showFilters,
    lang,
    removeAppliedFilterAriaLabel,
    isArticleListingPartOfKidsHub,
    isFixedFilters,
    counterFirstWord,
    counterMiddleWord,
    isArticlesCounterDisplayed,
    filtersTitle,
  } = props;
  const isArticlesCardListInViewport = useOnViewport(articlesCardListRef, '-25px');
  const limit = isSmallDevice ? cardsNumMobile : cardsNumDesktop;

  const additionalFilterParams =
    showManuallySelectedArticles && articlesLinks?.length
      ? Helpers.getAdditionalFilterParams(articlesLinks)
      : '';

  const {
    itemsToRender,
    activeListPage,
    handleActiveListPage,
    pageCount,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    itemsTotal,
    loading,
    countFilteredItems,
  } = useAlgoliaResponse<ArticleListingTypes.IArticleCard | IKidsHubArticlePreview>(
    limit,
    defaultArticles,
    showManuallySelectedArticles,
    articlesLinks,
    filters
  );

  const [articles, setArticles] = useState<
    Array<ArticleListingTypes.IArticleCard | IKidsHubArticlePreview>
  >(itemsToRender);

  useEffect(() => {
    if (!loading && itemsToRender.length) {
      setArticles(itemsToRender);
    }
  }, [loading, itemsToRender]);

  return (
    <div
      className={classNames('article-list-filter', {
        [`${bgSectionColor}-default-bg`]: bgSectionColor,
      })}
      data-test="ArticleListFilter"
    >
      <div className="pagination-anchor-block" id={COMPONENT_ID}>
        {(categories?.length || filterSections.length) &&
        process.env.GATSBY_ALGOLIA_INDEX_PREFIX ? (
          <AlgoliaFilters
            indexName={
              isArticleListingPartOfKidsHub
                ? `${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-kidsHub-articles`
                : `${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-articles`
            }
            filterSections={filterSections}
            checkboxStyles={checkboxStyles}
            customGlobalStyles={customGlobalStyles}
            filterButtonsTexts={filterButtonsTexts}
            categories={categories}
            currentPageId={currentPageId}
            masterPageId={masterPageId}
            masterPageLink={masterPageLink}
            idInMainDOM={COMPONENT_ID}
            showResultsBtn={showResultsBtn}
            clearAllBtn={clearAllBtn}
            filterMenuBgColor={filterMenuBgColor}
            categorySectionTitle={categorySectionTitle}
            saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
            handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleActiveListPage={handleActiveListPage}
            handleHitsResponseActivated={handleHitsResponseActivated}
            isHitsResponseActivated={isHitsResponseActivated}
            lang={lang}
            categoryFacetName={categoryFacetName}
            additionalFilterParams={isArticleListingPartOfKidsHub ? '' : additionalFilterParams}
            showFilters={showFilters}
            setFilters={setFilters}
            isSmallDevice={isSmallDevice}
            removeAppliedFilterAriaLabel={removeAppliedFilterAriaLabel}
            isFixedPosition={isFixedFilters}
            filtersTitle={filtersTitle}
          />
        ) : null}
        <div ref={articlesCardListRef} className="articles-card-list-wrapper">
          {!loading ? (
            <ArticleCardList
              areArticlesPartOfKidsHub={isArticleListingPartOfKidsHub}
              articles={articles}
              isShowLabels={showLabels}
              sorryMessage={sorryMessage}
              isArticlesCardListInViewport={isArticlesCardListInViewport}
              isSmallDevice={isSmallDevice}
              itemsTotal={itemsTotal}
              countFilteredItems={countFilteredItems}
              counterFirstWord={counterFirstWord}
              counterMiddleWord={counterMiddleWord}
              isArticlesCounter={isArticlesCounterDisplayed}
            />
          ) : null}
        </div>
        {itemsTotal > limit ? (
          <Pagination
            handleActiveListPage={handleActiveListPage}
            pageCount={pageCount}
            active={activeListPage}
            scrollTargetId={COMPONENT_ID}
          />
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentArticleListConfigs on IArticleListConfigs {
    cardsNumDesktop
    cardsNumMobile
    showLabels
    sorryMessage
  }

  fragment FragmentFilterArticleList on IFilterArticleList {
    showArticlesFiltersSidebar
    isFixedFilters
    counterFirstWord
    counterMiddleWord
    isArticlesCounterDisplayed
    filtersTitle
    filterArticleListFilterSections {
      properties {
        header
        isOpen
        operand
        attributeForFaceting
        variant
        filterItems {
          id
          title
        }
      }
    }
  }

  fragment FragmentArticlesFilters on IArticlesFilters {
    categoriesList {
      properties {
        filterableCategories {
          id
          title
          relatedPage
          relatedPageLink
          masterPageLink
          masterPageId
        }
        categorySectionTitle
      }
    }
    checkboxStyles {
      properties {
        bgColor {
          ...FragmentColorProps
        }
        signColor {
          ...FragmentColorProps
        }
      }
    }
    clearAllBtn {
      properties {
        defaultTextColor {
          ...FragmentColorProps
        }
        title
      }
    }
    customGlobalStyles {
      properties {
        defaultControlsBgColor {
          ...FragmentColorProps
        }
        defaultControlsTextColor {
          ...FragmentColorProps
        }
      }
    }
    showResultsBtn {
      properties {
        defaultBgColor {
          ...FragmentColorProps
        }
        defaultTextColor {
          ...FragmentColorProps
        }
        title
      }
    }
    filterMenuBgColor {
      ...FragmentColorProps
    }
    filterButtonsTexts {
      properties {
        filtersSelectLabel
        filtersHaveSelectedLabel
        filtersTogglerButtonText
        closeButtonText
        seeMoreButtonText
        seeLessButtonText
      }
    }
    masterPageId
    masterPageLink
    removeAppliedFilterAriaLabel
  }
`;

export default ArticleListFilter;
